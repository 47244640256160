import main from '../assets/phu_kien_tu_ruou/main.png';
import { Link } from 'react-router-dom';

import image1 from '../assets/phu_kien_tu_ruou/image1.png';
import image2 from '../assets/phu_kien_tu_ruou/image2.png';
import image3 from '../assets/phu_kien_tu_ruou/image3.png';
import image4 from '../assets/phu_kien_tu_ruou/image4.png';
import image5 from '../assets/phu_kien_tu_ruou/image5.png';

export const PhuKienTuRuouContent = () => {
  const accessories = [
    { id: 1, image: image1, name: 'Đợt nan nhôm tủ rượu' },
    { id: 2, image: image2, name: 'Nan tủ rượu' },
    { id: 3, image: image3, name: 'Giá treo ly rượu vang đôi' },
    { id: 4, image: image4, name: 'Giá treo ly rượu vang đơn' },
    { id: 5, image: image5, name: 'Đợt nan kính tủ rượu' },
  ];

  return (
    <div className="pt-32 sm:pt-32 md:pt-32 lg:pt-40  bg-gray-100 font-nunitoSans">
      <div className="container mx-auto flex justify-between items-center px-5">
        <h1 className="text-xl md:text-xl font-bold uppercase text-gray-800">
          Phụ kiện tủ rượu
        </h1>
      </div>
      <div className="container mx-auto px-5 mt-5">
        <img src={main} alt="Phụ kiện tủ bếp" className="object-cover h-full w-full rounded-lg shadow-lg" />
      </div>

      {/* Danh sách phụ kiện */}
      <div className="container mx-auto mt-8 sm:mt-5 pb-10 px-5 font-nunitoSans">
        <h2 className="text-center text-sm sm:text-sm md:text-xl lg:text-xl font-bold uppercase mb-8 text-gray-800">Danh sách phụ kiện</h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
          {accessories.slice(0).map((accessory) => (
            <div
              key={accessory.id}
              className="bg-white rounded-lg shadow-lg p-2 transition-transform transform hover:scale-105 hover:shadow-xl"
            >
              <img
                src={accessory.image}
                alt={accessory.name}
                className="w-auto h-auto object-cover rounded-md mb-4"
              />
              <h3 className="text-xl font-bold mb-2 text-center text-gray-800">{accessory.name}</h3>
              <div className="text-center">
                <Link
                  to={`/contact?item=${accessory.id}`}
                  className="text-blue-500 font-bold hover:text-red-700 transition-colors"
                >
                  Liên hệ
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
