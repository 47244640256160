import './App.css';
import { Route, Routes } from 'react-router-dom';
import { LetterFromFounder } from './page/LetterFromFounder';
import { Product } from './page/Product';
import { HomePage } from './page/HomePage';
import { TuRuouCanhKinh } from './page/TuRuouCanhKinh';
import { TuRuouFullKinh } from './page/TuRuouFullKinh';
import { Showroom } from './page/Showroom';
import { Contact } from './page/Contact';
import { PhuKienTuBep } from './page/PhuKienTuBep';
import { PhuKienPhongThayDo } from './page/PhuKienPhongThayDo';
import { PhuKienTuRuou } from './page/PhuKienTuRuou';
import { TuAoCanhKinh } from './page/TuAoCanhKinh';
import { TuAoFullKinh } from './page/TuAoFullKinh';
import { HeTuBepSmart } from './page/HeTuBepSmart';
import { HeTuBepLuxury } from './page/HeTuBepLuxury';
import { LuxuryKitchenElite } from './page/LuxuryKitchenElite';
import { LuxurySpaceGrandeur } from './page/LuxurySpaceGrandeur';
import { SmartKitchenPro } from './page/SmartKitchenPro';
import { SmartSpaceElegance } from './page/SmartSpaceElegance';
import { Project } from './page/Project';
import { Project1 } from './page/Project1';
import { Project2 } from './page/Project2';
import { Project3 } from './page/Project3';
import { Project4 } from './page/Project4';
import { Solution } from './page/Solution';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HomePageTuRuou } from './page/HomePageTuRuou';
import { HomePageTuAo } from './page/HomePageTuAo';
import { HomePageTuBep } from './page/HomePageTuBep';
function App() {
  return (
    <div className="App">
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/homepage/tu-ruou" element={<HomePageTuRuou />} />
        <Route path="/homepage/tu-ao" element={<HomePageTuAo />} />
        <Route path="/homepage/tu-bep" element={<HomePageTuBep />} />
        <Route path="/letter-from-founder" element={<LetterFromFounder />} />
        <Route path="/product" element={<Product />} />
        <Route path="/product/tu-ruou/tu-ruou-canh-kinh" element={<TuRuouCanhKinh />} />
        <Route path="/product/tu-ruou/tu-ruou-full-kinh" element={<TuRuouFullKinh />} />
        <Route path="/product/accessory/tu-bep" element={<PhuKienTuBep />} />
        <Route path="/product/accessory/phong-thay-do" element={<PhuKienPhongThayDo />} />
        <Route path="/product/accessory/tu-ruou" element={<PhuKienTuRuou />} />
        <Route path="/product/dressing-room/tu-ao-canh-kinh" element={<TuAoCanhKinh />} />
        <Route path="/product/dressing-room/tu-ao-full-kinh" element={<TuAoFullKinh />} />
        <Route path="/product/kitchen/smart" element={<HeTuBepSmart />} />
        <Route path="/product/kitchen/smart/pro" element={<SmartKitchenPro />} />
        <Route path="/product/kitchen/smart/elegance" element={<SmartSpaceElegance />} />
        <Route path="/product/kitchen/luxury" element={<HeTuBepLuxury />} />
        <Route path="/product/kitchen/luxury/luxury-kitchen-elite" element={<LuxuryKitchenElite />} />
        <Route path="/product/kitchen/luxury/luxury-space-grandeur" element={<LuxurySpaceGrandeur />} />
        <Route path="/project" element={<Project />} />
        <Route path="/project/project1" element={<Project1 />} />
        <Route path="/project/project2" element={<Project2 />} />
        <Route path="/project/project3" element={<Project3 />} />
        <Route path="/project/project4" element={<Project4 />} />
        <Route path="/solution" element={<Solution />} />
        <Route path="/showroom" element={<Showroom />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </div>
  );
}

export default App;
