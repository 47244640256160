import main from '../assets/phu_kien_phong_thay_do/main.png';
import { Link } from 'react-router-dom';
import { useState } from 'react';

import image1 from '../assets/phu_kien_phong_thay_do/image1.png';
import image2 from '../assets/phu_kien_phong_thay_do/image2.png';
import image3 from '../assets/phu_kien_phong_thay_do/image3.png';
import image4 from '../assets/phu_kien_phong_thay_do/image4.png';
import image5 from '../assets/phu_kien_phong_thay_do/image5.png';
import image6 from '../assets/phu_kien_phong_thay_do/image6.png';
import image7 from '../assets/phu_kien_phong_thay_do/image7.png';

export const PhuKienPhongThayDoContent = () => {
  const accessories = [
    { id: 1, image: image1, name: '(Grob) Kệ dựng đồ trang sức bọc da' },
    { id: 2, image: image2, name: '(Grob) Móc treo cavat' },
    { id: 3, image: image3, name: '(Grob) Kệ vắt quần áo kết hợp led' },
    { id: 4, image: image4, name: '(Hafele) Móc treo quần áo' },
    { id: 5, image: image5, name: '(Hafele) Tay nâng móc áo' },
    { id: 6, image: image6, name: '(Hafele) Khay đựng trang sức' },
    { id: 7, image: image7, name: '(Hafele) Thanh treo quần áo' }
  ];

  const [visibleItems, setVisibleItems] = useState(9);

  const handleShowMore = () => {
    setVisibleItems(visibleItems + 6);
  };

  return (
    <div className="pt-32 sm:pt-32 md:pt-32 lg:pt-40  bg-gray-100 font-nunitoSans">
      <div className="container mx-auto flex justify-between items-center px-5">
        <h1 className="text-xl md:text-xl font-bold uppercase text-gray-800">
          Phụ kiện phòng thay đồ
        </h1>
      </div>

      <div className="container mx-auto px-5 mt-5">
        <img src={main} alt="Phụ kiện tủ bếp" className="object-cover h-full w-full rounded-lg shadow-lg" />
      </div>

      <div className="container mx-auto mt-8 sm:mt-5 pb-10 px-5 font-nunitoSans">
        <h2 className="text-center text-sm sm:text-sm md:text-xl lg:text-xl font-bold uppercase mb-8 text-gray-800">Danh sách phụ kiện</h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
          {accessories.slice(0, visibleItems).map((accessory) => (
            <div
              key={accessory.id}
              className="bg-white rounded-lg shadow-lg p-2 transition-transform transform hover:scale-105 hover:shadow-xl"
            >
              <img
                src={accessory.image}
                alt={accessory.name}
                className="w-auto h-auto object-cover rounded-md mb-4"
              />
              <h3 className="text-xl font-bold mb-2 text-center text-gray-800">{accessory.name}</h3>
              <div className="text-center">
                <Link
                  to={`/contact?item=${accessory.id}`}
                  className="text-blue-500 font-bold hover:text-red-700 transition-colors"
                >
                  Liên hệ
                </Link>
              </div>
            </div>
          ))}
        </div>

        {/* Nút Xem Thêm */}
        {visibleItems < accessories.length && (
          <div className="text-center mt-10">
            <button
              onClick={handleShowMore}
              className="px-6 py-3 bg-red-500 text-white font-bold rounded-lg shadow-lg hover:bg-red-700 transition-colors"
            >
              Xem thêm
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
