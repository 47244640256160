import React from 'react';
import main from '../assets/tu_ao_canh_kinh/main.png';
import { Link } from 'react-router-dom';
import { TuAoCanhKinhSlider } from './TuAoCanhKinhSlider';

export const TuAoCanhKinhContent = () => {
  return (
    <div className="pt-28 sm:pt-28 lg:pt-40 bg-gray-100 pb-12 font-nunitoSans px-2 md:px-2">
      <div className="flex justify-between w-full max-w-[93rem] items-center mx-auto">
        <div className="uppercase font-bold text-sm lg:text-xl">Tủ áo cánh kính</div>
        <div className="flex space-x-2 sm:space-x-2 md:space-x-4 lg:space-x-4">
          <Link
            to="/product/dressing-room/tu-ao-canh-kinh"
            className="hover:text-red-600 transition duration-300 text-[.65rem] lg:text-sm flex items-center"
          >
            <div className="uppercase">Tủ áo cánh kính</div>
          </Link>
          <div className="flex items-center h-full">|</div>
          <Link to="/product/dressing-room/tu-ao-full-kinh"
                className="hover:text-red-600 transition duration-300 text-[.65rem] lg:text-sm flex items-center"
          >
            <div className="uppercase">Tủ áo full kính</div>
          </Link>

        </div>
      </div>

      <div className="mt-5 w-full max-w-[93rem] mx-auto border-b-2 border-gray-300 pb-4">
        <img
          src={main}
          alt="tủ áo cánh kính"
          className="h-full w-full object-cover rounded-lg shadow-lg transition-transform duration-300 hover:scale-105"
          // Thêm bo tròn, shadow, và hiệu ứng khi hover
        />

        <h5 className="mt-3 font-bold uppercase text-left animate-fadeIn">Tủ áo cánh kính</h5>
        <p className="text-left animate-fadeIn delay-100">- Tủ quần áo cánh kính không chỉ là nơi lưu trữ quần áo mà còn
          là điểm nhấn thẩm mỹ trong phòng ngủ. Thiết kế hiện đại, sự kết hợp giữa gỗ công nghiệp và kính cường lực tạo
          nên vẻ đẹp sang trọng và cá tính, phản ánh gu ăn mặc và cá nhân của chủ nhân. </p>
        <p className="text-left animate-fadeIn delay-100">
          - Với chất liệu cao cấp như khung nhôm hợp kim phủ bề mặt Anode kết hợp với kính cường lực, tủ quần áo cánh
          kính có khả năng chống cong vênh, kháng mối mọt, và chống trầy xước. Điều này đảm bảo tuổi thọ sử dụng lâu
          dài, tiết kiệm chi phí bảo trì.</p>
      </div>

      <div className="mt-5 w-full max-w-[93rem] mx-auto">
        <h5 className="mt-3 font-bold uppercase text-left animate-fadeIn delay-200">HÌNH ẢNH</h5>
        <TuAoCanhKinhSlider />
      </div>
    </div>
  );
};
