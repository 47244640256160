import { Link } from 'react-router-dom';
import tu_ruou_full_kinh from '../assets/tu_ruou_full_kinh.png';
import { TuRuouCanhKinhSlider } from './TuRuouCanhKinhSlider';
import React from 'react';
import { TuRuouFullKinhSlider } from './TuRuouFullKinhSlider';

export const TuRuouFullKinhContent = () => {
  return (
    <div className="pt-28 sm:pt-28 lg:pt-40 bg-gray-100 pb-12 font-nunitoSans px-2 md:px-2">
      <div className="flex justify-between w-full max-w-[97rem] items-center mx-auto">
        <div className="uppercase font-bold text-sm lg:text-xl">TỦ RƯỢU full KÍNH</div>
        <div className="flex space-x-2 sm:space-x-2 md:space-x-4 lg:space-x-4">
          <Link
            to="/product/tu-ruou/tu-ruou-canh-kinh"
            className="hover:text-red-600 transition duration-300 text-[.65rem] lg:text-sm flex items-center"  // Hiệu ứng khi hover
          >
            <div>TỦ RƯỢU CÁNH KÍNH</div>
          </Link>
          <div className="flex items-center h-full">|</div>
          <Link to="/product/tu-ruou/tu-ruou-full-kinh"
                className="hover:text-red-600 transition duration-300 text-[.65rem] lg:text-sm flex items-center"
          >
            <div >TỦ RƯỢU FULL KÍNH</div>
          </Link>

        </div>
      </div>

      <div className="mt-5 w-full max-w-[97rem] mx-auto border-b-2 border-gray-300 pb-4">
        <img
          src={tu_ruou_full_kinh}
          alt="tủ rượu cánh kính"
          className="h-full w-full object-cover rounded-lg shadow-lg transition-transform duration-300 hover:scale-105"
          // Thêm bo tròn, shadow, và hiệu ứng khi hover
        />

        <h5 className="mt-3 font-bold uppercase text-left animate-fadeIn">TỦ RƯỢU full KÍNH</h5>
        <p className="text-left animate-fadeIn delay-100">Hệ tủ rượu full kính là sự kết hợp hoàn hảo giữa hệ khung nhôm
          hợp kim, được xử lý bề mặt bằng công nghệ Anodeiz hiện đại, line đánh xước tinh tế và mềm mại. Mang đến cảm
          giác sang trọng tới tay khách hàng. Cùng với các vách kính cường lực chắc chắn, an toàn 6 mặt kính, màu đen
          mờ, giúp khách hàng dễ dàng quan sát, thao tác dễ dàng khi sử dùng thường ngày.</p>
      </div>

      <div className="mt-5 w-full max-w-[97rem] mx-auto">
        <h5 className="mt-3 font-bold uppercase text-left animate-fadeIn delay-200">HÌNH ẢNH</h5>
        <TuRuouFullKinhSlider />
      </div>
    </div>
  );
};