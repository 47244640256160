import { Link } from 'react-router-dom';
import elite_main from '../assets/elite_main.png';
import React from 'react';
import { LuxuryKitchenEliteSlider } from './LuxuryKitchenEliteSlider';

export const LuxuryKitchenEliteContent = () => {
  return (
    <div className="pt-28 sm:pt-28 lg:pt-40 bg-gray-100 pb-12 font-nunitoSans px-2 md:px-2">
      <div className="flex justify-between w-full max-w-[93rem] items-center mx-auto">
        <div className="uppercase font-bold text-sm lg:text-xl">LUXURY KITCHEN ELITE</div>
        <div className="flex space-x-2 sm:space-x-2 md:space-x-4 lg:space-x-4">
          <Link
            to="/product/kitchen/smart"
            className="hover:text-red-600 transition duration-300 text-[.65rem] lg:text-sm flex items-center" // Hiệu ứng khi hover
          >
            <div className="uppercase">hệ tủ bếp smart</div>
          </Link>
          <div className="flex items-center h-full" >|</div>
          <Link to="/product/kitchen/luxury"
                className="hover:text-red-600 transition duration-300 text-[.65rem] lg:text-sm flex items-center"
          >
            <div className="uppercase">hệ tủ bếp luxury</div>
          </Link>

        </div>
      </div>

      <div className="mt-5 w-full max-w-[93rem] mx-auto border-b-2 border-gray-300 pb-4">
        <img
          src={elite_main}
          alt="tủ áo cánh kính"
          className="h-full w-full object-cover rounded-lg shadow-lg transition-transform duration-300 hover:scale-105"
          // Thêm bo tròn, shadow, và hiệu ứng khi hover
        />

        <h5 className="mt-3 font-bold uppercase text-left animate-fadeIn">LUXURY KITCHEN ELITE</h5>
        <p className="text-left animate-fadeIn delay-100">Hệ tủ bếp sang trọng với các tính năng ưu việt và thiết kế
          đẳng cấp, được phát triển để đáp ứng nhu cầu của những người yêu thích sự hoàn hảo trong không gian bếp.
          Luxury Kitchen Elite không chỉ là một hệ tủ bếp, mà là một tác phẩm nghệ thuật mang đến sự tinh tế và phong
          cách cho căn bếp của bạn.</p>
      </div>

      <div className="mt-5 w-full max-w-[93rem] mx-auto">
        <h5 className="mt-3 font-bold uppercase text-left animate-fadeIn delay-200">HÌNH ẢNH</h5>
        <LuxuryKitchenEliteSlider />
      </div>
    </div>
  );
};