import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import React, { useState } from 'react';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import { SiZalo } from 'react-icons/si';
import { ContactFloatingButton } from '../components/ContactFloatingButton';
import { message } from 'antd';
export const Contact = () => {

  const [phone, setPhone] = useState('');
  const [subject, setSubject] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handlePhoneChange = (e) => {
    const input = e.target.value;
    const phonePattern = /^[0-9\s+-]*$/;

    if (phonePattern.test(input)) {
      setPhone(input);
    } else {
      message.error('Chỉ nhập số và các ký tự hợp lệ: số, dấu cộng (+), dấu gạch (-).').then(r => r);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // URL của Google Form và ID các trường input
    const googleFormURL = "https://docs.google.com/forms/d/e/1FAIpQLSfXEoPtqVlAaD6SVcna3vNRRRQ1QGWcRXJbiRmdkigwRdcPqA/formResponse";
    const formData = new FormData();
    formData.append('entry.1952753373', phone); // ID trường Google Form cho số điện thoại
    formData.append('entry.1754484126', subject); // ID trường Google Form cho chủ đề
    formData.append('entry.1098706926', name); // ID trường Google Form cho họ và tên
    formData.append('entry.262706884', email); // ID trường Google Form cho email
    formData.append('entry.567577173', message); // ID trường Google Form cho nội dung

    fetch(googleFormURL, {
      method: 'POST',
      mode: 'no-cors',
      body: formData,
    }).then(() => {
      message.success('Lời nhắn đã được gửi thành công!').then(r => r);
      // Reset form fields
      setPhone('');
      setSubject('');
      setName('');
      setEmail('');
      setMessage('');
    }).catch((error) => {
      console.error('Lỗi khi gửi dữ liệu:', error);
      message.error('Gửi dữ liệu thất bại.').then(r => r);
    });
  };

  return (
    <div>
      <Header />
      <div className="font-nunitoSans mt-8 lg:mt-24 bg-gray-100 py-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-10 w-full max-w-[97rem] mx-auto mt-14 px-4">
          {/* Contact Info */}
          <div className="text-left w-full max-w-lg mx-auto">
            <div className="uppercase font-bold text-xl mb-6 text-center sm:text-left">
              VIETNEXT HOME - Giải pháp nội thất, phong cách sống
            </div>
            <div className="uppercase font-bold">VIETNEXT HOME HÀ NỘI</div>
            <p className="mb-2">Địa chỉ: Số 1, Lô B50, Khu B KĐT Gleximco - Lê Trọng Tấn, Dương Nội, Hà Đông</p>
            <p className="mb-2">Hotline: 0985573613</p>
            <p>Gmail: vietnextsolutions@gmail.com</p>

            {/* Social Media Icons */}
            <div className="flex sm:justify-start space-x-4 mt-4">
              <a href="https://www.facebook.com/VietNextHome?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
                <FaFacebook
                  className="text-3xl text-blue-600 hover:text-blue-800 transition duration-300 transform hover:scale-110 hover:shadow-lg p-1 border-2 border-blue-600 rounded-full" />
              </a>
              <a href="https://zalo.me/0985573613" target="_blank" rel="noopener noreferrer">
                <SiZalo
                  className="text-3xl text-blue-500 hover:text-blue-700 transition duration-300 transform hover:scale-110 hover:shadow-lg p-1 border-2 border-blue-500 rounded-full" />
              </a>
              <a href="https://www.instagram.com/vietnexthome/" target="_blank" rel="noopener noreferrer">
                <FaInstagram
                  className="text-3xl text-pink-600 hover:text-pink-800 transition duration-300 transform hover:scale-110 hover:shadow-lg p-1 border-2 border-pink-600 rounded-full" />
              </a>
            </div>


          </div>

          {/* Form Section */}
          <div className="w-full max-w-lg mx-auto">
            <div className="uppercase text-xl font-bold text-center sm:text-left">GỬI LỜI NHẮN ĐẾN CHÚNG TÔI</div>

            <form onSubmit={handleSubmit} className="mt-8 space-y-6">
              <input
                type="text"
                id="subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                className="block w-full p-2.5 text-sm text-gray-900 bg-gray-300 rounded-lg border border-gray-300 shadow-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-100 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Nhập chủ đề *"
                required
              />

              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="block w-full p-2.5 text-sm text-gray-900 bg-gray-300 rounded-lg border border-gray-300 shadow-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-100 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Nhập họ và tên *"
                required
              />


              <input
                type="tel"
                id="phone"
                pattern="^[0-9\s+-]*$"
                value={phone}
                onChange={handlePhoneChange}
                className="block w-full p-2.5 text-sm text-gray-900 bg-gray-300 rounded-lg border border-gray-300 shadow-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-100 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Nhập số điện thoại *"
                onKeyDown={(e) => {
                  if (e.key === ' ') {
                    e.preventDefault(); // Prevent space input
                  }
                }}
                maxLength={10}
                required
              />

              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="block w-full p-2.5 text-sm text-gray-900 bg-gray-300 rounded-lg border border-gray-300 shadow-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-100 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Nhập email *"
                required
              />

              <textarea
                id="message"
                rows="4"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="block w-full p-2.5 text-sm text-gray-900 bg-gray-300 rounded-lg border border-gray-300 shadow-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-100 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Nhập nội dung"
                required
              ></textarea>

              <div className="text-center sm:text-left">
                <button
                  type="submit"
                  className="py-2.5 px-5 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 hover:text-blue-700 focus:ring-4 focus:ring-gray-100 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:text-white"
                >
                  Gửi tin nhắn
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />

      <ContactFloatingButton />
    </div>
  );
};
