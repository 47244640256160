import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { HomePageSlider } from '../components/HomePageSlider';
import { HomePageContent } from '../components/HomePageContent';
import { ContactFloatingButton } from '../components/ContactFloatingButton';

export const HomePage = () => {
  return (
    <div>
      <Header isHomePage={true}/>
      <HomePageSlider />
      <HomePageContent/>
      <Footer />
      <ContactFloatingButton/>
    </div>
  );
};