import { Header } from '../components/Header';
import { LetterFromFounderContent } from '../components/LetterFromFounderContent';
import { Footer } from '../components/Footer';
import { ContactFloatingButton } from '../components/ContactFloatingButton';
export const LetterFromFounder = () => {
  return (
    <div>
      <Header />
      <LetterFromFounderContent />
      <Footer />
      <ContactFloatingButton/>
    </div>
  );
};
