import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';

import image1 from '../assets/luxury_kitchen_elite/image1.jpg'
import image3 from '../assets/luxury_kitchen_elite/image3.JPG'
import image4 from '../assets/luxury_kitchen_elite/image4.JPG'
import image5 from '../assets/luxury_kitchen_elite/image5.jpg'
import image6 from '../assets/luxury_kitchen_elite/image6.JPG'

export const LuxuryKitchenEliteSlider = () => {
  const projects = [
    {
      id: 1,
      image: image1,
    },
    {
      id: 2,
      image: image3,
    },
    {
      id: 3,
      image: image4,
    },
    {
      id: 4,
      image: image5,
    },
    {
      id: 5,
      image: image6,
    },
  ];

  return (
    <div className="relative w-full max-w-[97rem] mx-auto mt-4">
      <Swiper
        modules={[Navigation, Autoplay]}
        spaceBetween={30}
        slidesPerView={1} // Show 1 slide per view on small screens
        breakpoints={{
          640: { slidesPerView: 1 }, // for mobile view
          768: { slidesPerView: 2 }, // for tablets
          1024: { slidesPerView: 3 }, // for desktops
        }}
        autoplay={{ delay: 3000 }}
        loop={true}
      >
        {projects.map((project) => (
          <SwiperSlide key={project.id}>
            <div className="flex flex-col h-full items-center bg-white shadow-lg rounded-lg overflow-hidden">
              <img
                src={project.image}
                alt={project.title}
                className="w-full h-80 object-cover rounded-lg shadow-lg transition-transform duration-300 hover:scale-105"  // Đặt chiều cao cho hình ảnh
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
