import { Link } from 'react-router-dom';
import main from '../assets/smart_space_elegance/main.jpg';
import React from 'react';
import { SmartSpaceEleganceSlider } from './SmartSpaceEleganceSlider';

export const SmartSpaceEleganceContent = () => {
  return (
    <div className="pt-28 sm:pt-28 lg:pt-40 bg-gray-100 pb-12 font-nunitoSans px-2 md:px-2">
      <div className="flex justify-between w-full max-w-[93rem] items-center mx-auto">
        <div className="uppercase font-bold text-sm lg:text-xl">SMART SPACE ELEGANCE</div>
        <div className="flex space-x-2 sm:space-x-2 md:space-x-4 lg:space-x-4">
          <Link
            to="/product/kitchen/smart"
            className="hover:text-red-600 transition duration-300 text-[.65rem] lg:text-sm flex items-center" // Hiệu ứng khi hover
          >
            <div className="uppercase">hệ tủ bếp smart</div>
          </Link>
          <div className="flex items-center h-full">|</div>
          <Link to="/product/kitchen/luxury"
                className="hover:text-red-600 transition duration-300 text-[.65rem] lg:text-sm flex items-center"
          >
            <div className="uppercase">hệ tủ bếp luxury</div>
          </Link>

        </div>
      </div>

      <div className="mt-5 w-full max-w-[93rem] mx-auto border-b-2 border-gray-300 pb-4">
        <img
          src={main}
          alt="tủ áo cánh kính"
          className="h-full w-full object-cover rounded-lg shadow-lg transition-transform duration-300 hover:scale-105"
          // Thêm bo tròn, shadow, và hiệu ứng khi hover
        />

        <h5 className="mt-3 font-bold uppercase text-left animate-fadeIn">SMART SPACE ELEGANCE</h5>
        <p className="text-left animate-fadeIn delay-100">Hệ tủ bếp thông minh được thiết kế đặc biệt để tối ưu hóa
          không gian và mang đến vẻ đẹp tinh tế, là sự kết hợp hoàn hảo giữa chức năng và thẩm mỹ. Với mục tiêu tạo ra
          một không gian bếp vừa tiện nghi vừa đẹp mắt, hệ tủ này sử dụng các giải pháp thiết kế thông minh để khai thác
          tối đa mọi mét vuông trong căn bếp của bạn.</p>
      </div>

      <div className="mt-5 w-full max-w-[93rem] mx-auto">
        <h5 className="mt-3 font-bold uppercase text-left animate-fadeIn delay-200">HÌNH ẢNH</h5>
        <SmartSpaceEleganceSlider />
      </div>
    </div>
  );
};