import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { SmartSpaceEleganceContent } from '../components/SmartSpaceEleganceContent';
import { ContactFloatingButton } from '../components/ContactFloatingButton';
import React from 'react';

export const SmartSpaceElegance = () => {
  return (
    <div>
      <Header/>
      <SmartSpaceEleganceContent/>
      <Footer/>
      <ContactFloatingButton />
    </div>
  )
}