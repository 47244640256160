import React from 'react';

import solution4 from '../assets/home_page_solution/image1.png';
import solution3 from '../assets/home_page_solution/image2.png';
import solution1 from '../assets/home_page_solution/image3.png';
import solution2 from '../assets/home_page_solution/image4.png';

import image1 from '../assets/choose_us/image1.svg';
import image2 from '../assets/choose_us/image2.svg';
import image3 from '../assets/choose_us/image3.svg';

import my_product_1 from '../assets/my_products/my_product_1.png';
import my_product_2 from '../assets/my_products/my_product_2.png';
import my_product_3 from '../assets/my_products/my_product_3.png';
import my_product_4 from '../assets/my_products/my_product_4.png';

import showroom_address from '../assets/showroom_address.png';
import { HomePageProjects } from './HomePageProjects';
import { Link } from 'react-router-dom';

export const HomePageContent = () => {
  const achievements = [
    { id: 1, image: image1, content: '10 năm kinh nghiệm trong lĩnh vực' },
    { id: 2, image: image3, content: '3680 công trình đã được thực hiện' },
    { id: 3, image: image2, content: 'Đội ngũ làm việc chuyên nghiệp' },
  ];

  const myProducts = [
    { id: 1, image: my_product_1, title: 'Tủ bếp', link: "/homepage/tu-bep" },
    { id: 2, image: my_product_3, title: 'Tủ rượu', link: "/homepage/tu-ruou" },
    { id: 3, image: my_product_4, title: 'Tủ áo', link: "/homepage/tu-ao" },
    { id: 4, image: my_product_2, title: 'Phụ kiện', link: "/product/accessory/tu-bep" },
  ];

  return (
    <div className="px-2 pt-12 sm:px-4 md:px-8 lg:px-16 bg-gray-100 font-nunitoSans">
      {/* Tại sao chọn chúng tôi */}
      <section className="text-center mb-3">
        <p className="uppercase text-black text-lg md:text-2xl font-bold ">
          Tại sao chọn chúng tôi?
        </p>
      </section>

      {/* Achievements */}
      <section className="mb-12">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 md:gap-8 max-w-screen-lg mx-auto">
          {achievements.map((item) => (
            <div key={item.id} className="flex flex-col items-center p-4 md:p-6 bg-white shadow-lg rounded-lg">
              <img src={item.image} alt={`Thành tựu ${item.id}`} className="w-12 h-12 md:w-16 md:h-16 mb-4" />
              <p className="text-sm md:text-lg text-center whitespace-nowrap">
                {item.content}
              </p>
            </div>
          ))}
        </div>
      </section>

      <section className="text-center mx-auto mb-14 w-full max-w-[90%] md:max-w-[72.3%]">
        <p className="uppercase text-black text-lg md:text-2xl font-bold">
          Sản phẩm của chúng tôi
        </p>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-8 lg:gap-12 mt-3 w-full mx-auto">
          {myProducts.map((item) => (
            <div
              key={item.id}
              className="flex flex-col items-center p-6 bg-white shadow-lg rounded-lg transition-transform duration-300 hover:scale-105"
            >
              <Link to={item.link}>
              <img
                src={item.image}
                alt={`Product ${item.id}`}
                className="w-full h-full md:w-[220px] md:h-[220px] object-cover mb-4 rounded-lg shadow-md transition-transform duration-300 hover:scale-110"
              />
              </Link>
              <p className="text-sm md:text-lg font-nunitoSans text-center uppercase text-red-800 font-bold">
                {item.title}
              </p>
            </div>
          ))}
        </div>
      </section>



      {/* Khối giải pháp */}
      <div className="relative w-full max-w-[90%] md:max-w-[72.3%] mx-auto mb-12 flex flex-col lg:flex-row">
        {/* Hình ảnh lệch trái */}
        <div className="grid grid-cols-2 grid-rows-3 gap-2 sm:gap-3 lg:gap-4 w-full lg:w-[60%] max-h-[500px] overflow-hidden lg:relative">
          <div className="row-span-3 transition-transform duration-300 transform hover:scale-105 hover:shadow-lg">
            <img src={solution1} alt="solution desc" className="w-full h-full object-cover rounded-lg" />
          </div>
          <div
            className="col-start-1 row-start-4 transition-transform duration-300 transform hover:scale-105 hover:shadow-lg">
            <img src={solution2} alt="solution desc" className="w-full h-full object-cover rounded-lg" />
          </div>
          <div
            className="row-span-2 col-start-2 row-start-1 transition-transform duration-300 transform hover:scale-105 hover:shadow-lg">
            <img src={solution3} alt="solution desc" className="w-full h-full object-cover rounded-lg" />
          </div>
          <div
            className="row-span-2 col-start-2 row-start-3 transition-transform duration-300 transform hover:scale-105 hover:shadow-lg">
            <img src={solution4} alt="solution desc" className="w-full h-full object-cover rounded-lg" />
          </div>
        </div>


        {/* Khối text lệch phải và trùm lên hình ảnh */}
        <div
          className="relative w-full lg:w-[50%] flex items-center -pl-8 lg:-pl-12 lg:absolute lg:top-0 lg:right-0 lg:bottom-0 lg:pl-8 mt-4">
          <div className="bg-white bg-opacity-80 py-8 px-10 lg:px-12 rounded-lg shadow-lg">
            <h3 className="uppercase text-black text-lg md:text-2xl font-bold mb-4 text-left">GIẢI PHÁP</h3>
            <p className="text-sm md:text-base mb-4 text-left">
              Với 10 năm kinh nghiệm trong lĩnh vực thiết kế, thi công nội thất. Đội ngũ chúng tôi
              đã tâm huyết nghiên cứu phát triển ra các hệ sản phẩm:
            </p>
            <ul className="list-disc pl-5 text-sm md:text-base uppercase font-bold mb-4 text-left">
              <li>Hệ tủ full kính</li>
              <li>Hệ cánh kính 3D</li>
            </ul>
            <Link to="/solution">
              <button type="button"
                      className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-4 md:px-5 py-2.5">
                Xem thêm
              </button>
            </Link>
          </div>
        </div>
      </div>


      {/* Dự án tiêu biểu */}
      <section className="text-center mb-5 sm:mb-5 lg:mb-6">
        <p className="uppercase text-black text-lg md:text-2xl font-bold">
          Dự án tiêu biểu
        </p>
        <HomePageProjects />
      </section>

      <section className="text-center">
        <Link to="/project">
          <button type="button"
                  className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mx-auto">
            Xem thêm
          </button>
        </Link>
      </section>

      {/* Showroom */}
      <section
        className="flex flex-col lg:flex-row justify-center items-start gap-8 pb-12 w-full max-w-[90%] md:max-w-[72.3%] mx-auto mt-8">
        <div className="lg:text-left lg:w-1/2 text-left">
          <div className="text-black text-lg md:text-2xl font-black font-nunitoSans mb-4">
            SHOWROOM
          </div>
          <div className="text-black text-sm md:text-lg lg:text-lg leading-7 ">
            <p className="font-bold">VIETNEXT HOME HÀ NỘI</p>
            <p className="mt-2">
              Địa chỉ: Số 1, Lô B50, Khu B KĐT Gleximco - Lê Trọng Tấn, Dương Nội, Hà Đông
            </p>
            <p className="mt-2">Hotline: 0985573613</p>
            <p className="mt-2">Gmail: vietnextsolutions@gmail.com</p>
          </div>
        </div>
        <div className="relative w-full lg:w-2/3 h-auto flex-grow">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.916407444147!2d105.7337561!3d20.9959877!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3134532a0a0d0c97%3A0x9a484aff898c0da0!2sVietNext%20Home!5e0!3m2!1sen!2s!4v1726494333662!5m2!1sen!2s"
            className="w-full h-[100%] rounded-lg"
            allowFullScreen
            loading="lazy"
            title="Google Maps Embed"
            referrerPolicy="no-referrer-when-downgrade">

          </iframe>
        </div>
      </section>
    </div>
  );
};
