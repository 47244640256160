import { Link } from 'react-router-dom';
import smart_kitchen_pro from '../assets/he_tu_bep_smart_luxury/smart_kitchen_pro.png';
import smart_space_elegance from '../assets/he_tu_bep_smart_luxury/smart_space_elegance.png';
import React from 'react';

export const HeTuBepSmartContent = () => {
  return (
    <div className="pt-28 sm:pt-28 lg:pt-40 bg-gray-100 pb-12 font-nunitoSans">
      <div className="flex justify-between w-full max-w-[97rem] items-center mx-auto px-4 md:px-8">
        <div className="uppercase font-bold text-sm lg:text-xl">
          Hệ tủ bếp smart
        </div>
        <div className="flex space-x-2 sm:space-x-2 md:space-x-4 lg:space-x-4">
          <Link
            to="/product/kitchen/smart"
            className="hover:text-red-600 transition duration-300 text-[.65rem] lg:text-sm flex items-center"
          >
            <div className="uppercase">Hệ tủ bếp smart</div>
          </Link>
          <div className="flex items-center h-full">|</div>
          <Link
            to="/product/kitchen/luxury"
            className="hover:text-red-600 transition duration-300 text-[.65rem] lg:text-sm flex items-center"
          >
            <div className="uppercase">Hệ tủ bếp luxury</div>
          </Link>
        </div>
      </div>

      {/* Content Section */}
      <div
        className="flex flex-wrap justify-between w-full max-w-[97rem] items-center mx-auto mt-10 px-4 md:px-8 gap-6">
        {/* Left Item */}
        <div className="flex flex-col items-center">
          <Link to="/product/kitchen/smart/pro">
            <div
              className="overflow-hidden rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-500">
              <img
                src={smart_kitchen_pro}
                alt="Tủ áo cánh kính"
                className="w-full max-w-[30rem] lg:max-w-[40rem] rounded-lg object-cover"
              />
            </div>
          </Link>
          <div className="mt-4 uppercase font-bold text-lg md:text-xl lg:text-2xl text-center">
            SMART KITCHEN PRO
          </div>
        </div>

        {/* Right Item */}
        <div className="flex flex-col items-center">
          <Link to="/product/kitchen/smart/elegance">
            <div
              className="overflow-hidden rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-500">
              <img
                src={smart_space_elegance}
                alt="Tủ bếp luxury"
                className="w-full max-w-[30rem] lg:max-w-[40rem] rounded-lg object-cover"
              />
            </div>
          </Link>
          <div className="mt-4 uppercase font-bold text-lg md:text-xl lg:text-2xl text-center">
            SMART SPACE ELEGANCE
          </div>
        </div>
      </div>
    </div>
  );
};
