import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaChevronDown } from 'react-icons/fa';
import { FaBars } from 'react-icons/fa';
import { FaTimes } from 'react-icons/fa';
import logo from '../assets/logo_header.png';

export const Header = ({ isHomePage }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [isTop, setIsTop] = useState(true);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleSubMenu = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  useEffect(() => {
    if (isHomePage) {
      const handleScroll = () => {
        if (window.scrollY > 0) {
          setIsTop(false);
        } else {
          setIsTop(true);
        }
      };

      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    } else {
      setIsTop(false);  // For non-home pages, make the header background apply by default
    }
  }, [isHomePage]);

  return (
    <div className="font-nunitoSans">
      <nav
        className={`${
          isTop ? 'bg-transparent text-gray-100' : 'bg-gradient-to-b from-[#f7f8fa] to-[#e5e7eb]'
        } fixed top-0 left-0 w-full z-50 shadow-lg backdrop-blur-md transition-all duration-300 text-gray-900`}
      >
        <div className="mx-auto max-w-[97rem] sm:px-6 lg:px-8 h-auto py-2">
          <div className="flex h-full items-center justify-between px-4 sm:px-8">
            <div className="flex-shrink-0">
              <Link to="/">
                <img className="h-16 w-auto sm:h-24" src={logo} alt="Company logo" />
              </Link>
            </div>

            {/* Mobile Menu Button */}
            <div className="sm:hidden">
              <button
                onClick={toggleMenu}
                className="focus:outline-none relative"
              >
                {/* Icon with animation */}
                {isMenuOpen ? (
                  <FaTimes className="w-8 h-8 transition-transform duration-300 transform rotate-180" />
                ) : (
                  <FaBars className="w-8 h-8 transition-transform duration-300 transform rotate-0" />
                )}
              </button>
            </div>

            {/* Desktop Navigation Links */}
            <div className="hidden sm:flex flex-1 items-center justify-center space-x-12">
              <Link
                to="/letter-from-founder"
                className="text-lg font-bold hover:text-red-500 px-4 transition-all duration-300"
              >
                Giới thiệu
              </Link>

              {/* Sản phẩm dropdown for desktop */}
              <div className="relative group">
                <button
                  className="text-lg font-bold hover:text-red-500 px-4 flex items-center transition-all duration-300">
                  Sản phẩm
                  <FaChevronDown className="ml-2 transform group-hover:rotate-180 transition-transform duration-300" />
                </button>
                <div
                  className="absolute top-full left-0 mt-2 bg-black bg-opacity-70 shadow-lg rounded-lg z-10 w-[55rem] opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 ease-in-out"
                >
                  <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 p-6 gap-4 text-white">
                    <div className="space-y-2">
                      <p className="font-bold text-lg uppercase">Tủ bếp</p>
                      <div className="space-y-1">
                        <Link
                          to="/product/kitchen/smart"
                          className="block py-2 hover:bg-gray-700 px-3 rounded-md transition-colors duration-300"
                        >
                          Hệ tủ bếp Smart
                        </Link>
                        <Link
                          to="/product/kitchen/luxury"
                          className="block py-2 hover:bg-gray-700 px-3 rounded-md transition-colors duration-300"
                        >
                          Hệ tủ bếp Luxury
                        </Link>
                      </div>
                    </div>
                    <div className="space-y-2">
                      <p className="font-bold text-lg uppercase">Tủ rượu</p>
                      <div className="space-y-1">
                        <Link
                          to="/product/tu-ruou/tu-ruou-canh-kinh"
                          className="block py-2 hover:bg-gray-700 px-3 rounded-md transition-colors duration-300"
                        >
                          Tủ rượu cánh kính
                        </Link>
                        <Link
                          to="/product/tu-ruou/tu-ruou-full-kinh"
                          className="block py-2 hover:bg-gray-700 px-3 rounded-md transition-colors duration-300"
                        >
                          Tủ rượu full kính
                        </Link>
                      </div>
                    </div>
                    <div className="space-y-2">
                      <p className="font-bold text-lg uppercase">Phòng thay đồ</p>
                      <div className="space-y-1">
                        <Link
                          to="/product/dressing-room/tu-ao-canh-kinh"
                          className="block py-2 hover:bg-gray-700 px-3 rounded-md transition-colors duration-300"
                        >
                          Tủ áo cánh kính
                        </Link>
                        <Link
                          to="/product/dressing-room/tu-ao-full-kinh"
                          className="block py-2 hover:bg-gray-700 px-3 rounded-md transition-colors duration-300"
                        >
                          Tủ áo full kính
                        </Link>
                      </div>
                    </div>
                    <div className="space-y-2">
                      <p className="font-bold text-lg uppercase">Phụ kiện</p>
                      <div className="space-y-1">
                        <Link
                          to="/product/accessory/tu-bep"
                          className="block py-2 hover:bg-gray-700 px-3 rounded-md transition-colors duration-300"
                        >
                          Phụ kiện tủ bếp
                        </Link>
                        <Link
                          to="/product/accessory/phong-thay-do"
                          className="block py-2 hover:bg-gray-700 px-3 rounded-md transition-colors duration-300"
                        >
                          Phụ kiện phòng thay đồ
                        </Link>
                        <Link
                          to="/product/accessory/tu-ruou"
                          className="block py-2 hover:bg-gray-700 px-3 rounded-md transition-colors duration-300"
                        >
                          Phụ kiện tủ rượu
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Link to="/solution"
                    className="text-lg font-bold hover:text-red-500 px-4 transition-all duration-300">
                Giải pháp
              </Link>
              <Link to="/project"
                    className="text-lg font-bold  hover:text-red-500 px-4 transition-all duration-300">
                Dự án

              </Link>
              <Link to="/showroom"
                    className="text-lg font-bold  hover:text-red-500 px-4 transition-all duration-300">
                Showroom
              </Link>
              <Link to="/contact"
                    className="text-lg font-bold hover:text-red-500 px-4 transition-all duration-300">
                Liên hệ
              </Link>
            </div>

          </div>

          {/* Mobile Menu */}
          <div
            className={`${
              isMenuOpen ? 'max-h-screen' : 'max-h-0'
            } overflow-hidden transition-max-height duration-500 ease-in-out mt-4 pl-3 space-y-2 bg-gray-100`}
          >
            <Link
              to="/letter-from-founder"
              className="block sm:text-xl pt-2 lg:text-lg font-bold  hover:text-red-500 transition-all duration-300 text-left"
            >
              Giới thiệu
            </Link>

            {/* Mobile Sản phẩm dropdown */}
            <div className="block">
              <button onClick={toggleSubMenu}
                      className="w-full sm:text-xl lg:text-lg font-bold  hover:text-red-500 transition-all duration-300 flex justify-start items-center">
                Sản phẩm
                <FaChevronDown
                  className={`ml-2 transform transition-transform duration-300 ${isSubMenuOpen ? 'rotate-180' : ''}`} />
              </button>

              {isSubMenuOpen && (

                <div className="pl-4 mt-2 space-y-1">
                  <Link to="/product/kitchen/smart"
                        className="block text-base font-medium text-gray-700 hover:text-red-500 text-left">
                    Hệ tủ bếp Smart
                  </Link>
                  <Link to="/product/kitchen/luxury"
                        className="block text-base font-medium text-gray-700 hover:text-red-500 text-left">
                    Hệ tủ bếp Luxury
                  </Link>
                  <Link to="/product/tu-ruou/tu-ruou-canh-kinh"
                        className="block text-base font-medium text-gray-700 hover:text-red-500 text-left">
                    Tủ rượu cánh kính
                  </Link>
                  <Link to="/product/tu-ruou/tu-ruou-full-kinh"
                        className="block text-base font-medium text-gray-700 hover:text-red-500 text-left">
                    Tủ rượu full kính
                  </Link>
                  <Link to="/product/dressing-room/tu-ao-canh-kinh"
                        className="block text-base font-medium text-gray-700 hover:text-red-500 text-left">
                    Tủ áo cánh kính
                  </Link>
                  <Link to="/product/dressing-room/tu-ao-full-kinh"
                        className="block text-base font-medium text-gray-700 hover:text-red-500 text-left">
                    Tủ áo full kính
                  </Link>
                  <Link to="/product/accessory/tu-bep"
                        className="block text-base font-medium text-gray-700 hover:text-red-500 text-left">
                    Phụ kiện tủ bếp
                  </Link>
                  <Link to="/product/accessory/phong-thay-do"
                        className="block text-base font-medium text-gray-700 hover:text-red-500 text-left">
                    Phụ kiện phòng thay đồ
                  </Link>
                  <Link to="/product/accessory/tu-ruou"
                        className="block text-base font-medium text-gray-700 hover:text-red-500 text-left">
                    Phụ kiện tủ rượu
                  </Link>
                </div>
              )}
            </div>
            <Link
              to="/solution"
              className="block sm:text-xl lg:text-lg font-bold  hover:text-red-500 transition-all duration-300 text-left"
            >
              Giải pháp
            </Link>
            <Link to="/project"
                  className="block sm:text-xl lg:text-lg font-bold  hover:text-red-500 transition-all duration-300 text-left">
              Dự án
            </Link>
            <Link to="/showroom"
                  className="block sm:text-xl lg:text-lg font-bold  hover:text-red-500 transition-all duration-300 text-left">
              Showroom
            </Link>
            <Link to="/contact"
                  className="block pb-2 sm:text-xl lg:text-lg font-bold  hover:text-red-500 transition-all duration-300 text-left">
              Liên hệ
            </Link>
          </div>
        </div>
      </nav>
    </div>
  );
};
