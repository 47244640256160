import { Header } from '../components/Header';
import { TuRuouCanhKinhContent } from '../components/TuRuouCanhKinhContent';
import { Footer } from '../components/Footer';
import { ContactFloatingButton } from '../components/ContactFloatingButton';
import React from 'react';

export const TuRuouCanhKinh = () => {
  return (
    <div>
      <Header/>
      <TuRuouCanhKinhContent/>
      <Footer/>
      <ContactFloatingButton />
    </div>
  )
}