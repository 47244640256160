import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { SmartKitchenProContent } from '../components/SmartKitchenProContent';
import { ContactFloatingButton } from '../components/ContactFloatingButton';
import React from 'react';

export const SmartKitchenPro = () => {
  return (
    <div>
      <Header />
      <SmartKitchenProContent />
      <Footer />
      <ContactFloatingButton />
    </div>
  );
};