import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { PhuKienTuBepContent } from '../components/PhuKienTuBepContent';
import { ContactFloatingButton } from '../components/ContactFloatingButton';
import React from 'react';

export const PhuKienTuBep = () => {
  return (
    <div>
      <Header/>
      <PhuKienTuBepContent/>
      <Footer/>
      <ContactFloatingButton />
    </div>
  )
}