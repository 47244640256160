import React from 'react';
import tu_ruou_canh_kinh from '../assets/tu_ruou_canh_kinh.png';
import { TuRuouCanhKinhSlider } from './TuRuouCanhKinhSlider';
import { Link } from 'react-router-dom';

export const TuRuouCanhKinhContent = () => {
  return (
    <div className="pt-28 sm:pt-28 lg:pt-40 bg-gray-100 pb-12 font-nunitoSans px-2 md:px-2">
      <div className="flex justify-between w-full max-w-[93rem] items-center mx-auto">
        <div className="uppercase font-bold text-sm lg:text-xl">TỦ RƯỢU CÁNH KÍNH</div>
        <div className="flex space-x-2 sm:space-x-2 md:space-x-4 lg:space-x-4">
          <Link
            to="/product/tu-ruou/tu-ruou-canh-kinh"
            className="hover:text-red-600 transition duration-300 text-[.65rem] lg:text-sm flex items-center" // Hiệu ứng khi hover
          >
            <div className="uppercase">TỦ RƯỢU CÁNH KÍNH</div>
          </Link>
          <div className="flex items-center h-full">|</div>
          <Link to="/product/tu-ruou/tu-ruou-full-kinh"
                className="hover:text-red-600 transition duration-300 text-[.65rem] lg:text-sm flex items-center"
          >
          <div className="uppercase">TỦ RƯỢU FULL KÍNH</div>
        </Link>
      </div>
    </div>

  <div className="mt-5 w-full max-w-[93rem] mx-auto border-b-2 border-gray-300 pb-4">
        <img
          src={tu_ruou_canh_kinh}
          alt="tủ rượu cánh kính"
          className="h-full w-full object-cover rounded-lg shadow-lg transition-transform duration-300 hover:scale-105"
          // Thêm bo tròn, shadow, và hiệu ứng khi hover
        />

        <h5 className="mt-3 font-bold uppercase text-left animate-fadeIn">TỦ RƯỢU CÁNH KÍNH</h5>
        <p className="text-left animate-fadeIn delay-100">Tủ rượu cánh kính thiết kế hiện đại, cánh kính trong suốt của tủ rượu giúp người dùng
          dễ dàng quan sát và trưng bày các loại rượu quý giá, đồng thời bảo vệ chúng khỏi bụi bẩn và ánh sáng. Kính cường lực thường được
          sử dụng để tăng cường độ bền và an toàn. Việc kết hợp giữa gỗ công nghiệp và kính tạo ra một vẻ ngoài hiện đại
          nhưng không kém phần ấm cúng và sang trọng.</p>
      </div>

      <div className="mt-5 w-full max-w-[93rem] mx-auto">
        <h5 className="mt-3 font-bold uppercase text-left animate-fadeIn delay-200">HÌNH ẢNH</h5>
        <TuRuouCanhKinhSlider/>
      </div>
    </div>
  );
};
