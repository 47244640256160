import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { Link } from 'react-router-dom';

import project1 from '../assets/projects/project1.png';
import project2 from '../assets/projects/project2.png';
import project3 from '../assets/projects/project3.png';
import project4 from '../assets/projects/project4.png';
import { ContactFloatingButton } from '../components/ContactFloatingButton';

export const Project = () => {
  const projects = [
    { id: 1, image: project1, name: 'Dự án 1', link: '/project/project1' },
    { id: 2, image: project2, name: 'Dự án 2', link: '/project/project2' },
    { id: 3, image: project3, name: 'Dự án 3', link: '/project/project3' },
    { id: 4, image: project4, name: 'Dự án 4', link: '/project/project4' },
  ];

  return (
    <div>
      <Header />

      <div className="bg-gray-100 mt-12 sm:mt-12 md:mt-24 lg:mt-30 py-8 font-nunitoSans px-1 md:px-2">
        <div className="max-w-[93rem] mx-auto mt-8 font-nunitoSans">
          <h2 className="text-2xl font-bold text-left mb-6 uppercase">Dự Án</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-14">
            {projects.map((project) => (
              <Link
                to={project.link}
                key={project.id}
                className="flex flex-col items-center bg-white rounded-lg shadow-lg overflow-hidden transform transition duration-300 hover:scale-105 hover:shadow-2xl"
              >
                <img
                  src={project.image}
                  alt={project.name}
                  className="w-full h-[20rem] object-cover transition duration-300 hover:opacity-90"
                />
                <div className="p-4 text-center">
                  <h3 className="text-lg uppercase font-semibold text-gray-800 hover:text-red-600 transition duration-300">
                    {project.name}
                  </h3>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>


      <Footer />
      <ContactFloatingButton/>
    </div>
  );
};
