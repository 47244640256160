import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { ContactFloatingButton } from '../components/ContactFloatingButton';
import React from 'react';
import { HomePageTuAoContent } from '../components/HomePageTuAoContent';
export const HomePageTuAo = () => {
  return (
    <div>
      <Header />
      <HomePageTuAoContent />
      <Footer />
      <ContactFloatingButton />
    </div>
  );
};