import main from '../assets/phu_kien_tu_bep/main.png';
import { Link } from 'react-router-dom';
import { useState } from 'react';

import image1 from '../assets/phu_kien_tu_bep/image1.png';
import image2 from '../assets/phu_kien_tu_bep/image2.png';
import image3 from '../assets/phu_kien_tu_bep/image3.png';
import image4 from '../assets/phu_kien_tu_bep/image4.png';
import image5 from '../assets/phu_kien_tu_bep/image5.png';
import image6 from '../assets/phu_kien_tu_bep/image6.png';
import image7 from '../assets/phu_kien_tu_bep/image7.png';
import image8 from '../assets/phu_kien_tu_bep/image8.png';
import image9 from '../assets/phu_kien_tu_bep/image9.png';
import image10 from '../assets/phu_kien_tu_bep/image10.png';
import image11 from '../assets/phu_kien_tu_bep/image11.png';
import image12 from '../assets/phu_kien_tu_bep/image12.png';
import image13 from '../assets/phu_kien_tu_bep/image13.png';
import image14 from '../assets/phu_kien_tu_bep/image14.png';
import image15 from '../assets/phu_kien_tu_bep/image15.png';

export const PhuKienTuBepContent = () => {
  const accessories = [
    { id: 1, image: image1, name: '(Blum) Hệ tay nâng 2 cánh' },
    { id: 2, image: image2, name: '(Blum) Tay nâng vuông góc' },
    { id: 3, image: image3, name: '(Blum) Tay nâng song song' },
    { id: 4, image: image4, name: '(Blum) Ngăn kéo đĩa muỗng' },
    { id: 5, image: image5, name: '(Blum) Ngăn kéo xoong nồi ' },
    { id: 6, image: image6, name: '(Blum) Ngăn kéo gia vị' },
    { id: 7, image: image7, name: '(Blum) Ngăn kéo dưới chậu rửa' },
    { id: 8, image: image8, name: '(Blum) Khay đựng đĩa' },
    { id: 9, image: image9, name: '(Blum) Ngăn kéo đa năng đồ khô' },
    { id: 10, image: image10, name: '(Hafele) Giỏ đa chức năng' },
    { id: 11, image: image11, name: '(Hafele) Giá để bát đĩa có khung' },
    { id: 12, image: image12, name: '(Hafele) Thùng gạo' },
    { id: 13, image: image13, name: '(Hafele) Giá đỡ bát đĩa kéo ra' },
    { id: 14, image: image14, name: '(Hafele) Ngăn kéo đa năng' },
    { id: 15, image: image15, name: '(Hafele) Giá đỡ đĩa kéo xuống' },
  ];

  const [visibleItems, setVisibleItems] = useState(9);

  const handleShowMore = () => {
    setVisibleItems(visibleItems + 6);
  };

  return (
    <div className="pt-28 sm:pt-28 md:pt-32 lg:pt-40  bg-gray-100 font-nunitoSans">
      <div className="container mx-auto flex justify-between items-center px-5">
        <h1 className="text-xl sm:text-xl md:text-xl lg:text-xl font-bold uppercase text-gray-800">
          Phụ kiện tủ bếp
        </h1>
      </div>
      <div className="container mx-auto px-5 mt-5">
        <img src={main} alt="Phụ kiện tủ bếp" className="object-cover h-full w-full rounded-lg shadow-lg" />
      </div>

      <div className="container mx-auto mt-8 sm:mt-5 pb-10 px-5">
        <h2 className="text-center text-sm sm:text-sm md:text-xl lg:text-xl font-bold uppercase mb-8 text-gray-800">Danh sách phụ kiện</h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
          {accessories.slice(0, visibleItems).map((accessory) => (
            <div
              key={accessory.id}
              className="bg-white rounded-lg shadow-lg p-2 transition-transform transform hover:scale-105 hover:shadow-xl"
            >
              <img
                src={accessory.image}
                alt={accessory.name}
                className="w-auto h-auto object-cover rounded-md mb-4"
              />
              <h3 className="text-xl font-bold mb-2 text-center text-gray-800">{accessory.name}</h3>
              <div className="text-center">
                <Link
                  to={`/contact?item=${accessory.id}`}
                  className="text-blue-500 font-bold hover:text-red-700 transition-colors"
                >
                  Liên hệ
                </Link>
              </div>
            </div>
          ))}
        </div>

        {visibleItems < accessories.length && (
          <div className="text-center mt-10">
            <button
              onClick={handleShowMore}
              className="px-6 py-3 bg-red-500 text-white font-bold rounded-lg shadow-lg hover:bg-red-700 transition-colors"
            >
              Xem thêm
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
