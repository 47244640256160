import React, { useState } from 'react';
import image1 from '../assets/solution_glass/image1.png';
import image2 from '../assets/solution_glass/image2.png';
import image3 from '../assets/solution_glass/image3.png';
import image4 from '../assets/solution_glass/image4.png';
import image5 from '../assets/solution_glass/image5.png';
import image6 from '../assets/solution_glass/image6.png';
import image7 from '../assets/solution_glass/image7.png';
import image8 from '../assets/solution_glass/image8.png';
import image9 from '../assets/solution_glass/image9.png';
import image10 from '../assets/solution_glass/image10.png';
import image11 from '../assets/solution_glass/image11.png';
import image12 from '../assets/solution_glass/image12.png';
import image13 from '../assets/solution_glass/image13.png';
import image14 from '../assets/solution_glass/image14.png';
import image15 from '../assets/solution_glass/image15.png';
import image16 from '../assets/solution_glass/image16.png';
import image17 from '../assets/solution_glass/image17.png';
import image18 from '../assets/solution_glass/image18.png';
import image19 from '../assets/solution_glass/image19.png';
import image20 from '../assets/solution_glass/image20.png';
import image21 from '../assets/solution_glass/image21.png';
import image22 from '../assets/solution_glass/image22.png';
import image23 from '../assets/solution_glass/image23.png';
import image24 from '../assets/solution_glass/image24.png';
import image25 from '../assets/solution_glass/image25.png';

// Import các hình ảnh khác tương tự

export const SolutionContent = () => {
  const [selectedCategory, setSelectedCategory] = useState('2K');

  const accessories = [
    { id: 1, image: image1, name: 'Hệ kính màu VN01', code: 'Code: VN01', category: '2K' },
    { id: 2, image: image2, name: 'Hệ kính màu VN02', code: 'Code: VN02', category: '2K' },
    { id: 3, image: image3, name: 'Hệ kính màu VN03', code: 'Code: VN03', category: '2K' },
    { id: 4, image: image4, name: 'Hệ kính màu VN04', code: 'Code: VN04' , category: '2K'},
    { id: 5, image: image5, name: 'Hệ kính màu VN05', code: 'Code: VN05' , category: '2K'},
    { id: 6, image: image6, name: 'Hệ kính màu VN06', code: 'Code: VN06' , category: '2K'},
    { id: 7, image: image7, name: 'Hệ kính màu VN07', code: 'Code: VN07' , category: '2K'},
    { id: 8, image: image8, name: 'Hệ kính màu VN08', code: 'Code: VN08' , category: '2K'},
    { id: 9, image: image9, name: 'Hệ kính màu VN09', code: 'Code: VN09' , category: '2K'},
    { id: 10, image: image10, name: 'Hệ kính màu VN10', code: 'Code: VN10' , category: '2K'},
    { id: 11, image: image11, name: 'Hệ kính màu VN11', code: 'Code: VN11', category: '2K' },
    { id: 12, image: image12, name: 'Hệ kính nhám VN12', code: 'Code: VN12', category: 'Nhám' },
    { id: 13, image: image13, name: 'Hệ kính nhám VN13', code: 'Code: VN13', category: 'Nhám' },
    { id: 14, image: image14, name: 'Hệ kính nhám VN14', code: 'Code: VN14' , category: 'Nhám'},
    { id: 15, image: image15, name: 'Hệ kính nhám VN15', code: 'Code: VN15' , category: 'Nhám'},
    { id: 16, image: image16, name: 'Hệ kính nhám VN16', code: 'Code: VN16' , category: 'Nhám'},
    { id: 17, image: image17, name: 'Hệ kính nhám VN17', code: 'Code: VN17' , category: 'Nhám'},
    { id: 18, image: image18, name: 'Hệ kính nhám VN18', code: 'Code: VN18' , category: 'Nhám'},
    { id: 19, image: image19, name: 'Hệ kính nhám VN19', code: 'Code: VN19' , category: 'Nhám'},
    { id: 20, image: image20, name: 'Kính trang trí VN20', code: 'Code: VN20', category: 'Trang Trí' },
    { id: 21, image: image21, name: 'Kính trang trí VN21', code: 'Code: VN21', category: 'Trang Trí' },
    { id: 22, image: image22, name: 'Kính trang trí VN22', code: 'Code: VN22', category: 'Trang Trí' },
    { id: 23, image: image23, name: 'Kính trang trí VN23', code: 'Code: VN23' , category: 'Trang Trí'},
    { id: 24, image: image24, name: 'Kính trang trí VN24', code: 'Code: VN19' , category: 'Trang Trí'},
    { id: 25, image: image25, name: 'Kính trang trí VN25', code: 'Code: VN20' , category: 'Trang Trí'},
    // Thêm các item khác cùng với trường category
  ];

  // Lọc các accessories theo hệ cánh kính được chọn
  const filteredAccessories = accessories.filter(
    (accessory) => accessory.category === selectedCategory
  );

  return (
    <div className="pt-28 sm:pt-28 lg:pt-40 bg-gray-50 py-10 font-nunitoSans">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center px-5">
        <h1 className="text-xl md:text-2xl font-bold uppercase text-gray-900">
          CÁNH KÍNH
        </h1>

        <div className="flex space-x-6 mt-4 md:mt-0">
          {['2K', 'Nhám', 'Trang Trí'].map((category) => (
            <div
              key={category}
              onClick={() => setSelectedCategory(category)}
              className={`cursor-pointer transition-all duration-300 ${
                selectedCategory === category
                  ? 'text-blue-600 border-b-2 border-blue-600'
                  : 'text-gray-700 hover:text-blue-500'
              } uppercase text-sm sm:text-sm md:text-sm lg-text-sm font-semibold font-nunitoSans`}
            >
              {category === '2K' ? 'HỆ CÁNH KÍNH MÀU 2K' : category === 'Nhám' ? 'HỆ CÁNH KÍNH NHÁM' : 'HỆ KÍNH TRANG TRÍ'}
            </div>
          ))}
        </div>
      </div>

      <div className="container mx-auto mt-8 sm:mt-8 lg:mt-10 px-5">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
          {filteredAccessories.map((accessory) => (
            <div
              key={accessory.id}
              className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-transform transform hover:scale-105"
            >
              <img
                src={accessory.image}
                alt={accessory.name}
                className="w-full h-auto object-cover rounded-md mb-4"
              />
              <h3 className="text-xl font-semibold mb-2 text-center text-gray-800">
                {accessory.name}
              </h3>
              <div className="text-center text-blue-500 font-semibold">
                {accessory.code}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};