import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';

import typical_project_cover_1 from '../assets/typical_projects_home_page/typical_project_cover_1.png';
import typical_project_cover_2 from '../assets/typical_projects_home_page/typical_project_cover_2.png';
import typical_project_cover_3 from '../assets/typical_projects_home_page/typical_project_cover_3.png';
import typical_project_cover_4 from '../assets/typical_projects_home_page/typical_project_cover_4.png';
import { Link } from 'react-router-dom';

export const HomePageProjects = () => {
  const projects = [
    {
      id: 1,
      image: typical_project_cover_2,
      title: 'Dự án - Thành phố Phúc Yên, tỉnh Vĩnh Phúc',
      summary: [
        'Tủ rượu cánh kính',
        'Tủ áo cánh kính',
      ],
      link: '/project/project1',
    },
    {
      id: 2,
      image: typical_project_cover_3,
      title: 'Dự án - Thành phố Hạ Long, tỉnh Quảng Ninh',
      summary: [
        "Tủ bếp cánh kính",
        "Tủ rượu cánh kính",
        "Tủ áo cánh kính"
      ],
      link: '/project/project2',
    },
    {
      id: 3,
      image: typical_project_cover_1,
      title: 'Dự án - Quận Hà Đông, thành phố Hà Nội',
      summary: [
        "Tủ rượu cánh kính"
      ],
      link: '/project/project3',
    },
    {
      id: 4,
      image: typical_project_cover_4,
      title: 'Dự án - Thành phố Việt Trì, tỉnh Phú Thọ',
      summary: [
        "Tủ bếp cánh kính"
      ],
      link: '/project/project4',
    }
  ];

  return (
    <div className="relative w-full max-w-[80rem] mx-auto mt-4 font-nunitoSans">
      <Swiper
        modules={[Navigation, Autoplay, Pagination]}
        spaceBetween={20}
        autoplay={{ delay: 3000 }}
        loop={true}
        breakpoints={{
          // Khi kích thước màn hình là >= 640px
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          // Khi kích thước màn hình là >= 768px
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          // Khi kích thước màn hình là >= 1024px
          1024: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          // Khi kích thước màn hình là >= 1280px
          1280: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        }}
        className="h-auto"
      >
        {/*todo: match link*/}
        {projects.map((project) => (
          <SwiperSlide key={project.id}>
            <Link to={project.link} className="block">
            <div className="flex flex-col h-[480px] items-center bg-white shadow-lg rounded-lg overflow-hidden">
              <img
                src={project.image}
                alt={project.title}
                className="w-full h-[330px] object-cover rounded-lg shadow-lg transition-transform duration-300 hover:scale-105"
                // Cố định chiều cao cho hình ảnh
              />
              <div className="flex flex-col justify-between px-4 py-8 h-full">
                <h3 className="text-lg font-bold text-gray-900 mb-1 whitespace-nowrap">{project.title}</h3>
                <ul className="text-sm text-gray-700 list-disc pl-5 text-left overflow-hidden">
                  {project.summary.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
