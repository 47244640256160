import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { SolutionContent } from './SolutionContent';
import { ContactFloatingButton } from '../components/ContactFloatingButton';

export const Solution = () => {
  return (
    <div>
      <Header />
      <SolutionContent />
      <Footer />
      <ContactFloatingButton />
    </div>
  );
};