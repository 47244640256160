import logo from '../assets/logo_footer.png';
import React, { useState } from 'react';
import './Footer.module.scss';
import { BsSend } from 'react-icons/bs';
import { message } from 'antd';

export const Footer = () => {
  const [phone, setPhone] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();


    const googleFormURL = 'https://docs.google.com/forms/d/e/1FAIpQLSf5gM9APe4YgSToL9e8ZXtTyCydav3tlfNUd6adjzSZqYL-UA/formResponse';


    const formData = new FormData();
    formData.append('entry.1719015541', phone);

    fetch(googleFormURL, {
      method: 'POST',
      mode: 'no-cors',
      body: formData,
    }).then(() => {
      message.success('Số điện thoại đã được gửi thành công!').then(r => r);
      setPhone(''); // Reset trường số điện thoại
    }).catch((error) => {
      console.error('Lỗi khi gửi dữ liệu:', error);
      message.error('Gửi dữ liệu thất bại.').then(r => r);
    });
  };

  const handlePhoneChange = (e) => {
    const input = e.target.value;
    const phonePattern = /^[0-9\s+-]*$/;

    if (phonePattern.test(input)) {
      setPhone(input);
    } else {
      // Optionally show an error message or ignore invalid input
      message.error('Chỉ nhập số và các ký tự hợp lệ: số, dấu cộng (+), dấu gạch (-).').then(r => r);
    }
  };


  return (
    <footer className="bg-gray-800 text-white py-8 px-5 font-nunitoSans">
      <div
        className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-10 text-left md:text-left">
        {/* Logo and Contact Section */}
        <div className="sm:py-2 md:py-2 lg:py-4 text-white md:w-full">
          <img src={logo} alt={'company logo'} className="h-16 w-auto md:mx-0 items-start" />
          <p className="text-[1rem] uppercase font-bold mt-4">Liên hệ</p>
          <p className="text-base font-light mt-3">Hotline: 0985573613</p>
          <p className="text-base font-light mt-1">Email: vietnextsolutions@gmail.com</p>
        </div>

        <div className="py-4 text-white md:w-full">
          <h3 className="text-[1rem] font-bold mb-4 uppercase">Hệ thống showroom</h3>

          {/* Showroom 1 */}
          <p className="text-[0.9rem] uppercase font-semibold mt-3">vietnext home hà nội</p>
          <p className="text-[0.8rem] sm:text-[.8rem] lg:text-[.875rem] mt-2 flex items-center">

            Số 1, Lô B50, Khu B KĐT Gleximco - Lê Trọng Tấn, Dương Nội, Hà Đông
          </p>

          {/* Showroom 2 */}
          <p className="text-[0.9rem] uppercase font-semibold mt-4">vietnext home vĩnh phúc</p>
          <p className="text-[0.8rem] sm:text-[.8rem] lg:text-[.875rem] mt-2 flex items-center">

            SN05-NP9, Đ.Lý Nam Đế, KĐT Sinh thái Nam Đầm Vạc, Khai Quang, Vĩnh Yên, Vĩnh Phúc.
          </p>
        </div>


        {/* Newsletter Section */}
        <div className="py-4 text-white md:w-full">
          <h3 className="text-[1rem] mb-4 uppercase font-bold">Đăng ký nhận tin</h3>
          <p className="text-base mt-3">Nhận thông tin về sản phẩm và những ưu đãi mới nhất.</p>
          <form onSubmit={handleSubmit} className="flex items-center justify-center md:justify-start mt-4 md:px-0">
            <input
              type="tel"
              pattern="^[0-9\s+-]*$"
              placeholder="Nhập số điện thoại của bạn"
              className="w-full md:w-3/4 p-2 border border-gray-300 rounded-l-md focus:outline-none focus:border-blue-500 text-white bg-gray-800"
              value={phone}
              onChange={handlePhoneChange}
              onKeyDown={(e) => {
                if (e.key === ' ') {
                  e.preventDefault(); // Prevent space input
                }
              }}
              maxLength={10}
              required
            />
            <button
              type="submit"
              className="bg-blue-500 text-white p-2 rounded-r-md hover:bg-blue-600 transition-all duration-300"
            >
              <BsSend size={24} />
            </button>
          </form>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="mt-8 border-t border-gray-700 py-1 text-center">
        <p className="text-sm text-gray-400">&copy; 2024 Vietnext Solutions. All rights reserved.</p>
      </div>
    </footer>
  );
};
