import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import showroom_address from '../assets/showroom_address.png';
import React from 'react';
import { ContactFloatingButton } from '../components/ContactFloatingButton';

export const Showroom = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className="pt-28 sm:pt-28 lg:pt-40 bg-gray-100 font-nunitoSans flex-grow">
        <section
          className="flex flex-col lg:flex-row justify-center items-start gap-8 w-full max-w-[90%] md:max-w-[75.3%] mx-auto pb-8"> {/* Đã thêm `pb-8` để giảm khoảng cách */}
          <div className="text-left lg:text-left lg:w-1/2">
            <div className="text-black text-lg md:text-2xl font-black font-nunitoSans mb-4">
              SHOWROOM
            </div>
            <div className="text-black text-sm md:text-lg lg:text-lg font-nunitoSans leading-7">
              <p className="font-bold">VIETNEXT HOME HÀ NỘI</p>
              <p className="mt-2">
                Địa chỉ: Số 1, Lô B50, Khu B KĐT Gleximco - Lê Trọng Tấn, Dương Nội, Hà Đông
              </p>
              <p className="mt-2">Hotline: 0985573613</p>
              <p className="mt-2">Gmail: vietnextsolutions@gmail.com</p>
            </div>
          </div>
          <div className="relative w-full lg:w-2/3 h-auto flex-grow">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.916407444147!2d105.7337561!3d20.9959877!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3134532a0a0d0c97%3A0x9a484aff898c0da0!2sVietNext%20Home!5e0!3m2!1sen!2s!4v1726494333662!5m2!1sen!2s"
              className="w-full h-[100%] rounded-lg"
              allowFullScreen
              loading="lazy"
              title="Google Maps Embed"
              referrerPolicy="no-referrer-when-downgrade">

            </iframe>
          </div>
        </section>
      </div>

      <Footer />

      <ContactFloatingButton />
    </div>
  );
};
