import chat_luong from '../assets/thu_ngo/chat_luong.jfif'
import sang_tao from '../assets/thu_ngo/sang_tao.jfif'
import khach_hang from '../assets/thu_ngo/khach_hang.jfif'
import chuyen_nghiep from '../assets/thu_ngo/chuyen_nghiep.jfif'
import doi_moi from '../assets/thu_ngo/doi_moi.jfif'

export const LetterFromFounderContent = () => {
  const coreValues = [
    {title: "Chất lượng tuyệt hảo: ", text:'Chúng tôi cam kết mang đến những sản phẩm và dịch vụ có chất lượng cao nhất, từ khâu thiết kế đến sản xuất và giao hàng, đảm bảo đáp ứng tiêu chuẩn quốc tế và nhu cầu của khách hàng.', image: chat_luong},
    {title: "Sáng tạo và đổi mới: ", text: 'Sáng tạo là trung tâm của mọi hoạt động của chúng tôi. Chúng tôi không ngừng tìm kiếm những giải pháp mới, áp dụng công nghệ tiên tiến và thiết kế độc đáo để mang đến những sản phẩm và dịch vụ vượt trội.', image: sang_tao},
    {title: "Khách hàng là trung tâm: ", text: 'Khách hàng là ưu tiên hàng đầu của chúng tôi. Chúng tôi lắng nghe, thấu hiểu và đáp ứng nhu cầu của khách hàng với sự tận tâm và chính xác, nhằm mang đến sự hài lòng tối đa.', image: khach_hang},
    {title: "Chuyên nghiệp và tận tâm: ", text: 'Đội ngũ nhân viên của chúng tôi được đào tạo chuyên sâu và làm việc với tinh thần trách nhiệm cao nhất. Chúng tôi luôn sẵn sàng hỗ trợ và cung cấp dịch vụ tốt nhất để đáp ứng kỳ vọng của khách hàng.', image: chuyen_nghiep},
    {title: "Đổi mới và phát triển bền vững: ", text: 'Chúng tôi cam kết phát triển bền vững và có trách nhiệm với môi trường. Chúng tôi áp dụng các quy trình sản xuất và tiêu chuẩn môi trường nhằm bảo vệ tài nguyên và giảm thiểu tác động tiêu cực đến môi trường.', image: doi_moi},
  ];

  return (
    <div className="flex flex-col items-center bg-gray-100 min-h-screen mt-20 sm:mt-24 lg:mt-24 px-2 md:px-2 font-nunitoSans">
      <div className="container text-left p-8 bg-white shadow-lg mb-12 mt-14 rounded-lg">
        <p className="text-red-700 font-passionsConflict text-6xl mb-5 sm:mb-5 lg:mb-6">
          Thư ngỏ
        </p>
        <div className="content font-nunitoSans space-y-4 text-sm sm:text-sm lg:text-lg">
          <p>Kính gửi Quý Khách Hàng,</p>
          <p>Trước tiên, chúng tôi xin gửi đến Quý Khách hàng lời chúc sức khỏe và thành công. Vietnext Home tự hào là một trong những nhà cung cấp hàng đầu về giải pháp nội thất cao cấp tại Việt Nam. Chúng tôi chuyên cung cấp các sản phẩm như tủ bếp hợp kim nhôm, cánh kính tủ quần áo và nội thất cánh kính, với cam kết mang đến chất lượng và sự đổi mới vượt trội.</p>
          <p>Với sự đầu tư mạnh mẽ vào nghiên cứu và phát triển, Vietnext Home không ngừng cập nhật công nghệ và hệ thống máy móc tiên tiến, nhằm nâng cao chất lượng sản phẩm và dịch vụ. Chúng tôi luôn hướng đến việc tạo ra những thiết kế độc đáo và cá nhân hóa, đáp ứng nhu cầu và sở thích riêng biệt của từng khách hàng. Chúng tôi tự hào về khả năng cung cấp dịch vụ linh hoạt và tinh tế, với sự lựa chọn đa dạng về kiểu dáng và vật liệu. Đội ngũ nhân viên chuyên nghiệp của chúng tôi sẵn sàng đồng hành và tư vấn, giúp Quý khách hàng tìm ra giải pháp tối ưu nhất cho không gian sống của mình.</p>
          <p>Vietnext Home sở hữu mạng lưới phân phối rộng khắp, đảm bảo giao hàng tận nơi một cách nhanh chóng và thuận tiện. Với đội ngũ nhân sự tận tâm, dày dạn kinh nghiệm và chuyên nghiệp, chúng tôi cam kết mang đến những sản phẩm và dịch vụ chất lượng nhất, đáp ứng nhu cầu của từng khách hàng. Chúng tôi tin tưởng rằng, với sự nỗ lực không ngừng và sự sáng tạo của đội ngũ, cùng mạng lưới khách hàng rộng lớn trên toàn quốc, Vietnext Home sẽ là đối tác tin cậy của Quý khách hàng trong việc tạo ra những không gian sống hoàn hảo và đẳng cấp.</p>
          <p>Trân trọng cảm ơn và mong được phục vụ Quý khách!</p>
        </div>
      </div>

      <div className="flex flex-col md:flex-row container justify-between gap-12">
        <div
          className="w-full md:max-w-[48%] text-left bg-white shadow-lg p-8 hover:shadow-2xl transition-shadow duration-300 ease-in-out rounded-lg">
          <h2 className="text-red-700 font-nunitoSans text-2xl lg:text-4xl mb-6 font-bold">
            Sứ Mệnh
          </h2>
          <p className="font-nunitoSans text-sm sm:text-sm lg:text-lg leading-relaxed text-justify">
            Sứ mệnh của VietNext Home là mang đến những giải pháp nội thất cao cấp và tinh tế nhất, tạo nên những không
            gian sống độc đáo và đẳng cấp cho khách hàng. Chúng tôi cam kết không ngừng đổi mới và sáng tạo, áp dụng
            công nghệ tiên tiến nhất để phát triển sản phẩm và dịch vụ, đáp ứng nhu cầu đa dạng và cá nhân hóa của từng
            khách hàng. Chúng tôi hướng đến việc xây dựng sự hài lòng lâu dài và tạo ra giá trị bền vững cho khách hàng
            thông qua sự chất lượng và tận tâm trong từng sản phẩm và dịch vụ.
          </p>
        </div>

        <div
          className="w-full md:max-w-[48%] text-left bg-white shadow-lg p-8 hover:shadow-2xl transition-shadow duration-300 ease-in-out rounded-lg">
          <h2 className="text-red-700 font-nunitoSans text-2xl lg:text-4xl mb-6 font-bold">
            Tầm Nhìn
          </h2>
          <p className="font-nunitoSans text-sm sm:text-sm lg:text-lg leading-relaxed text-justify">
            VietNext Home hướng tới việc trở thành thương hiệu hàng đầu và tiên phong trong ngành nội thất cao cấp tại
            Việt Nam. Chúng tôi phấn đấu không chỉ để dẫn đầu về công nghệ và thiết kế, mà còn để trở thành biểu tượng
            của sự đổi mới và sáng tạo trong ngành công nghiệp nội thất. Chúng tôi mong muốn xây dựng một cộng đồng
            khách hàng trung thành và tin tưởng vào chúng tôi như là lựa chọn hàng đầu cho những giải pháp nội thất cao
            cấp.
          </p>
        </div>
      </div>


      <div className="container mt-8 sm:mt-18 lg:mt-13">
        <h2 className="font-nunitoSans font-bold text-xl sm:text-xl lg:text-3xl text-left mb-3 sm:mb-3 lg:mb-5">
          Giá trị cốt lõi
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
          {coreValues.map((item, index) => (
            <div key={index} className="flex flex-col items-center p-6 bg-white shadow-lg rounded-lg">
              <img
                src={item.image}
                alt={`Core value ${index + 1}`}
                className="w-40 h-40 rounded-full mb-6 object-cover"
              />
              <div className="text-justify">
                <span className="font-nunitoSans text-sm sm:text-sm lg:text-lg font-bold">
                  {item.title}
                </span>
                <span>{item.text}</span>
              </div>

            </div>
          ))}
        </div>
      </div>

      <div
        className="w-full max-w-[96rem] text-left font-nunitoSans text-sm sm:text-sm lg:text-lg mt-5 sm:mt-5 lg:mt-8 mb-10">
        <p>Chúng tôi tin rằng, với sứ mệnh, tầm nhìn và giá trị cốt lõi này, VietNext Home sẽ không ngừng phát triển và
          mang đến cho khách hàng những trải nghiệm và sản phẩm tuyệt vời nhất.</p>
      </div>
    </div>
  );
};
