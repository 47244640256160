import { Link } from 'react-router-dom';
import tuaocanhkinh from '../assets/tu_ao_canh_kinh/homepage.png';
import tuaofullkinh from '../assets/tu_ao_full_kinh/homepage.png';
import React from 'react';

export const HomePageTuAoContent = () => {
  return (
    <div className="pt-32 lg:pt-40 bg-gray-100 pb-12 font-nunitoSans">
      <div className="flex justify-between w-full max-w-[97rem] items-center mx-auto px-4 md:px-8">
        <div className="uppercase font-bold text-xl sm:text-xl">
          PHÒNG THAY ĐỒ
        </div>
      </div>

      {/* Content Section */}
      <div
        className="flex flex-wrap justify-between w-full max-w-[97rem] items-center mx-auto mt-10 px-4 md:px-8 gap-6">
        {/* Left Item */}
        <div className="flex flex-col items-center">
          <Link to="/product/dressing-room/tu-ao-canh-kinh">
            <div
              className="overflow-hidden rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-500">
              <img
                src={tuaocanhkinh}
                alt="tủ áo cánh kính"
                className="w-full max-w-[30rem] lg:max-w-[40rem] rounded-lg object-cover"
              />
            </div>
          </Link>
          <div className="mt-4 uppercase font-bold text-lg md:text-xl lg:text-2xl text-center">
            TỦ ÁO CÁNH KÍNH
          </div>
        </div>

        {/* Right Item */}
        <div className="flex flex-col items-center">
          <Link to="/product/dressing-room/tu-ao-full-kinh">
            <div
              className="overflow-hidden rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-500">
              <img
                src={tuaofullkinh}
                alt="tủ áo full kính"
                className="w-full max-w-[30rem] lg:max-w-[40rem] rounded-lg object-cover"
              />
            </div>
          </Link>
          <div className="mt-4 uppercase font-bold text-lg md:text-xl lg:text-2xl text-center">
            TỦ ÁO full KÍNH
          </div>
        </div>
      </div>
    </div>
  );
};
