import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import main from '../assets/projects/1/main.jpg';
import React from 'react';
import { Project1Slider } from '../components/Project1Slider';
import { ContactFloatingButton } from '../components/ContactFloatingButton';

export const Project1 = () => {
  return (
    <div className="font-nunitoSans">
      <Header />

      <div className="bg-gray-100 pt-28 sm:pt-28 md:pt-32 lg:pt-40 py-8 font-nunitoSans px-1 md:px-2">
        <div className="flex justify-between w-full max-w-[93rem] items-center mx-auto">
          <div className="text-sm sm:text-sm md:text-xl lg:text-xl font-bold uppercase text-gray-800">Dự án - Thành phố Phúc
            Yên, tỉnh Vĩnh Phúc
          </div>
        </div>

        <div className="mt-5 w-full max-w-[93rem] mx-auto border-b-2 border-gray-300 pb-4">
          <img
            src={main}
            alt="tủ áo cánh kính"
            className="h-full w-full object-cover rounded-lg shadow-lg transition-transform duration-300 hover:scale-105"
            // Thêm bo tròn, shadow, và hiệu ứng khi hover
          />

          <h5 className="mt-3 font-bold uppercase text-left animate-fadeIn">Dự án - Thành phố Phúc Yên, tỉnh Vĩnh
            Phúc</h5>
          <p className="text-left animate-fadeIn delay-100">- Tủ rượu cánh kính</p>
          <p className="text-left animate-fadeIn delay-100">- Tủ áo cánh kính</p>
        </div>

        <div className="mt-5 w-full max-w-[93rem] mx-auto mb-20">
          <h5 className="mt-3 font-bold uppercase text-left animate-fadeIn delay-200">HÌNH ẢNH</h5>
          <Project1Slider />
        </div>
      </div>

      <Footer />
      <ContactFloatingButton />
    </div>
  );
};