import React, { useState, useEffect, useRef } from 'react';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import { SiZalo } from 'react-icons/si';
import { FiX } from 'react-icons/fi';
import styles from './ContactFloatingButton.module.scss';

export const ContactFloatingButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef(null);

  const toggleIcons = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="fixed bottom-8 right-8 z-50" ref={buttonRef}>
      <div className={`${styles.iconsContainer} ${isOpen ? styles.open : styles.closed}`}>
        {isOpen && (
          <>
            <a
              href="https://www.facebook.com/VietNextHome?mibextid=LQQJ4d"
              target="_blank"
              rel="noopener noreferrer"
              className={`${styles.icon} text-blue-600 hover:text-blue-800`}
            >
              <FaFacebook />
            </a>
            <a
              href="https://zalo.me/0985573613"
              target="_blank"
              rel="noopener noreferrer"
              className={`${styles.icon} ${styles.iconZalo} hover:bg-blue-700`}
            >
              <SiZalo />
            </a>
            <a
              href="https://www.instagram.com"
              target="_blank"
              rel="noopener noreferrer"
              className={`${styles.icon} text-pink-600 hover:text-pink-800`}
            >
              <FaInstagram />
            </a>
            <button
              onClick={toggleIcons}
              className={`${styles.buttonOpen}`}
            >
              <FiX className={`${styles.iconX}`} />
            </button>
          </>
        )}
      </div>
      {!isOpen && (
        <button
          onClick={toggleIcons}
          className={`${styles.button} ${!isOpen ? styles.vibrate : ''}`}
        >
          Liên hệ
        </button>
      )}
    </div>
  );
};
