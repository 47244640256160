import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';
import slider1 from '../assets/home_page_slider/slider1.png';
import slider2 from '../assets/home_page_slider/slider2.png';
import slider3 from '../assets/home_page_slider/slider3.png';

export const HomePageSlider = () => {
  const slides = [
    { id: 1, image: slider1, title: 'Giải pháp nội thất', subtitle: 'Nâng tầm phong cách sống' },
    { id: 2, image: slider2, title: 'Thiết kế thông minh' },
    { id: 3, image: slider3, title: 'Phong cách hiện đại' },
  ];

  return (
    <div className="relative w-full overflow-hidden">
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={0}
        slidesPerView={1}
        pagination={{ clickable: true }}
        autoplay={{ delay: 3000 }}
        loop={true}
        className="h-[40vh] sm:h-[50vh] md:h-[60vh] lg:h-[70vh]"
      >
        {slides.map((slide) => (
          <SwiperSlide key={slide.id}>
            <div
              className="bg-cover bg-center h-full flex items-center justify-center text-white"
              style={{ backgroundImage: `url(${slide.image})` }}
            >
              <div className="bg-black bg-opacity-50 p-4 md:p-8 lg:p-12 rounded-lg max-w-2xl mx-auto text-center">
                <h2 className="text-xl md:text-xl lg:text-2xl font-bold uppercase mb-2">
                  {slide.title}
                </h2>
                {slide.subtitle && (
                  <p className="text-sm md:text-lg lg:text-sm bg-red-800 bg-opacity-75 p-2 rounded uppercase">
                    {slide.subtitle}
                  </p>
                )}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
