import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Navigation } from 'swiper/modules';

import image1 from '../assets/tu_ao_canh_kinh/new_version/image1.png'
import image2 from '../assets/tu_ao_canh_kinh/new_version/image2.png'
import image3 from '../assets/tu_ao_canh_kinh/new_version/image3.png'
import image4 from '../assets/tu_ao_canh_kinh/new_version/image4.png'
import image5 from '../assets/tu_ao_canh_kinh/new_version/image5.png'

export const TuAoCanhKinhSlider = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const projects = [
    { id: 1, image: image1 },
    { id: 2, image: image2 },
    { id: 3, image: image3 },
    { id: 4, image: image4 },
    { id: 5, image: image5 }
  ];

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  return (
    <div className="relative w-full max-w-[97rem] mx-auto mt-4">
      <Swiper
        modules={[Navigation, Autoplay]}
        spaceBetween={30}
        loop={true}
        autoplay={{ delay: 3000 }}
        breakpoints={{
          // Cho màn hình nhỏ
          320: {
            slidesPerView: 1,
          },
          // Cho tablet
          768: {
            slidesPerView: 2,
          },
          // Cho màn hình lớn hơn (desktop)
          1024: {
            slidesPerView: 3,
          },
          // Cho màn hình rất lớn
          1440: {
            slidesPerView: 4,
          },
        }}
      >
        {projects.map((project) => (
          <SwiperSlide key={project.id}>
            <div
              className="flex flex-col h-full items-center bg-white shadow-lg rounded-lg overflow-hidden"
              onClick={() => handleImageClick(project.image)}
            >

              <img
                src={project.image}
                alt={`Project Image ${project.id}`}
                className="w-full h-80 object-cover rounded-lg shadow-lg transition-transform duration-300 hover:scale-105"
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
