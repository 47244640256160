import React from 'react';
import main from '../assets/tu_ao_full_kinh/main.jpeg';
import { Link } from 'react-router-dom';
import { TuAoFullKinhSlider } from './TuAoFullKinhSlider';

export const TuAoFullKinhContent = () => {
  return (
    <div className="pt-28 sm:pt-28 lg:pt-40 bg-gray-100 pb-12 font-nunitoSans px-2 md:px-2">
      <div className="flex justify-between w-full max-w-[93rem] items-center mx-auto">
        <div className="uppercase font-bold text-sm lg:text-xl">Tủ áo full kính</div>
        <div className="flex space-x-2 sm:space-x-2 md:space-x-4 lg:space-x-4">
          <Link
            to="/product/dressing-room/tu-ao-canh-kinh"
            className="hover:text-red-600 transition duration-300 text-[.65rem] lg:text-sm flex items-center"
          >
            <div className="uppercase">Tủ áo cánh kính</div>
          </Link>
          <div className="flex items-center h-full">|</div>
          <Link to="/product/dressing-room/tu-ao-full-kinh"
                className="hover:text-red-600 transition duration-300 text-[.65rem] lg:text-sm flex items-center"
          >
            <div className="uppercase">Tủ áo full kính</div>
          </Link>

        </div>
      </div>

      <div className="mt-5 w-full max-w-[93rem] mx-auto border-b-2 border-gray-300 pb-4">
        <img
          src={main}
          alt="tủ áo cánh kính"
          className="h-full w-full object-cover rounded-lg shadow-lg transition-transform duration-300 hover:scale-105"
          // Thêm bo tròn, shadow, và hiệu ứng khi hover
        />

        <h5 className="mt-3 font-bold uppercase text-left animate-fadeIn">Tủ áo full kính</h5>
        <p className="text-left animate-fadeIn delay-100">- Giải pháp hệ khung nhôm 3D chắc chắn tối ưu, bản rộng khung
          vừa phải tinh tế.</p>
        <p className="text-left animate-fadeIn delay-100"> - Bề mặt khung nhôm hợp kim được xử lý Anodeiz đánh xước
          line.</p>
        <p className="text-left animate-fadeIn delay-100">- Hệ vách hoàn toàn bằng kính cường lực, 6 mặt kính, màu sắc
          đa dạng, bề mặt trơn bóng dễ dàng vệ sinh, tính thẩm mỹ cao.</p>
        <p className="text-left animate-fadeIn delay-100">- Độ bền lâu dài theo thời gian với sự kết hợp giữa khung nhôm
          hợp kim Anodeiz và vách kính cường lực chắc nhắn, hoàn toàn không cong vênh, mối mọt,...</p>
      </div>

      <div className="mt-5 w-full max-w-[93rem] mx-auto">
        <h5 className="mt-3 font-bold uppercase text-left animate-fadeIn delay-200">HÌNH ẢNH</h5>
        <TuAoFullKinhSlider />
      </div>
    </div>
  );
};
